import React from "react";
import './assets/css/Pages.css';
import CoinSpin from './assets/img/coin-spin.gif'
import CoinPiece from './Coin/CoinPiece'

export default function Coin() {
    return (
        <>
            <div className={'Pages-Page'}>
                <h1 className={'Pages-Header'}>Challenge Coin</h1>
                <p className={'Pages-Body'}>
                    This site was originally created as a tool to be used by my patrons
                    on <a href={'https://www.patreon.com/FirewallsDontStopDragons'}>Patreon</a>.
                    As part of a promotion I ran in the summer of 2021, I created a very limited
                    run of some very cool challenge coins!
                    If you're not familiar with the concept of a challenge coin, you can check the
                    Wikipedia
                    entry <a href={'https://en.wikipedia.org/wiki/Challenge_coin'}>here</a>.
                    But basically, it's something usually given as a very special
                    thank you or to indicate membership in an elite organization.
                </p>
                <p className={'Pages-Body'}>
                    These aren't just regular, every day challenge coins, though. I also wanted to create something that my
                    patrons could use to enhance their digital security.
                    So these coins also function as a sort of "dice coin". They have the numbers from
                    1 to 20 around the edge and a little nub on the back. On a flat surface, you
                    can <i>spin</i> this coin and stop it with your finger to randomly "roll" a number!
                    (The number right next to your finger is the roll result - in the video below, the
                    result is "7".)
                </p>
                <p className={'Pages-Body'}>
                    If you would like one of these super-cool, highly-collectible, security-enhancing
                    challenge coins, you'll have to wait for the next Patreon promotion!
                    See <a href={'https://www.patreon.com/posts/51637580'}>this post</a> for
                    info.
                </p>                <div align={'center'}>
                    <img alt={'coin sping'} src={CoinSpin}/>
                </div>


                <h1 className={'Pages-Header'}>Coin Details</h1>
                <p className={'Pages-Body'}>
                    Each coin is 2" (5 cm) in diameter and weighs a hefty 1.8 ounces (51 grams).
                    They come in three metal finishes: gold, silver and copper. I did my best to capture
                    the coins with my iPhone, but the pictures below just don't do justice to the real
                    color and detail. In person, they look even better, trust me!
                    You can see the details better if you right click on them and select
                    "view image" in another tab/window. (On mobile phones, click each coin to reveal
                    the back side view.)
                </p>
            </div>
            {/* These generate one coin set of each type in file ./Coin/CoinPiece.js */}
            <CoinPiece type='Gold'/>
            <CoinPiece type='Silver'/>
            <CoinPiece type='Copper'/>
        </>
    );
}