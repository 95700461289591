import React from "react";
export default function Why() {
    return (
        <>
            <div className={'Pages-Page'}>
                <h1 className={'Pages-Header'}>Why Use a Passphrase?</h1>
                <p className={'Pages-Body'}>
                    I hate passwords – at least if I have to create and remember them.
                    This is why I use a password manager (and you should, too).
                    And yet, there are still times when we're forced to use a password that we can
                     memorize - for example, the vault password for your password manager.
                </p>
                <p className={'Pages-Body'}>
                    So the next question is: what's the difference beteen a password and a passphrase?
                    Well, a <i>password</i> is a sequence of keyboard characters;
                    a <i>passphrase</i> is a sequence of dictionary
                    words. Now, surely you’ve heard that to create strong passwords, you need to use all types
                    of characters: upper case, lower case, numbers and special characters. And that’s true.
                    We’re also told to avoid using dictionary words, common phrases, quotes, slogans, and
                    so on – because it’s too easy to guess these. And that is also true.
                    Therefore, when I say that passphrases can be just as strong as passwords, you should
                    rightly be suspicious. How can that be true? The short answer is: <b>math</b>.
                </p>
                <p className={'Pages-Body'}>
                    At the most basic level, password (and passphrase) strength comes down
                    to <i>guessability</i>. That is, how hard is it to guess? The bad guys know that
                    we <a href={'https://en.wikipedia.org/wiki/List_of_the_most_common_passwords'}>
                    suck at choosing passwords</a>. They have automated their password guessing tools to
                    start with all the common passwords people choose, and then move on to combinations of
                    English dictionary words, pop culture terms and phrases,
                    dates and so on. But if a passphrase uses dictionary words, wouldn’t that make it easy for a
                    computer to guess? Not if you select the words <i>randomly</i> and you use enough words.
                </p>
                <p className={'Pages-Body'}>
                    Once you remove all the easy-to-guess stuff, the bad guys are forced to start guessing all
                    possible values - this is called a <i>brute force attack</i>. That's where you want
                    to be. To get the full math on this, you should read
                    my <a href={'https://firewallsdontstopdragons.com/how-when-to-use-a-passphrase/'}>blog
                    article</a>.
                    But basically, if you choose enough random words, you can create a passphrase that's
                    sufficiently strong enough to thwart most brute force attacks. As one example,
                    a 6-word random passphrase is roughly as strong as a random 12-character password.
                </p>
                <p className={'Pages-Body'}>
                    For a more detailed explanation, see
                    my <a href={'https://www.patreon.com/FirewallsDontStopDragons'}>blog article</a>.
                </p>
            </div>
        </>
    );
}