import React from "react";
import './assets/css/Pages.css';
import { Link } from 'react-router-dom'

export default function AboutPage() {

    return (
        <>
            <div className={'Pages-Page'}>
                <h1 className={'Pages-Header'}>About</h1>
                <p className={'Pages-Body'}>
                    I created this website for two reasons. First and foremost, I wanted a fun, Dungeons
                    & Dragons themed tool for generating secure passphrases!
                    I'm a security and privacy
                    advocate. I also happen to love the fantasy genre and role playing games. These two
                    passions first game together when I wrote my
                    book, <a href={'https://firewallsdontstopdragons.com/buy-the-book/'}><i>Firewalls
                    Don't Stop Dragons</i></a>. That became
                    a <a href={'https://firewallsdontstopdragons.com/'}>blog,</a>
                    a <a href={'https://firewallsdontstopdragons.com/newsletter/new-newsletter/'}>newsletter</a>,
                    and eventually
                    a  <a href={'https://podcast.firewallsdontstopdragons.com/'}>podcast</a>.
                </p>
                <p className={'Pages-Body'}>
                    But I also created this site so that my patrons could use their
                    spiffy <Link to={'/coin'}>d20 Challenge Coin</Link> to create kick-butt passphrases!
                </p>

                <h1 className={'Pages-Header'}>Word List</h1>
                <p className={'Pages-Body'}>
                    I based my word list off of the excellent diceware list from
                    the <a href={'https://www.eff.org/deeplinks/2016/07/new-wordlists-random-passphrases'}>Electionic
                    Frontier Foundation</a>. Because that list used five regular 6-sided dice, it had 7,776 words
                    in it (that is, 6<sup>5</sup>). Because I used three d20's, I needed 8000 words (20<sup>3</sup>). I padded their list
                    with a bunch of fantasy, D&D and mythology terms.
                </p>
                <p className={'Pages-Body'}>
                    You can download the text file version of
                    my word list <a href={process.env.PUBLIC_URL +'/word_list.txt'}>here</a>.
                </p>

                <h1 className={'Pages-Header'}>Shout Outs</h1>
                <p className={'Pages-Body'}>
                    I'd like to give a VERY special and heartfelt thanks to some of the people who helped
                    me create this site and my super-cool custom challenge coin!
                </p>
                <li className={'Pages-List'}>
                    <b><a href={'https://www.mythicnation.com/'}>Mythic Nation</a>:</b> for
                    helping me to design and manufactur the coin
                </li>
                <li className={'Pages-List'}>
                    <b><a href={'https://www.facebook.com/Art-By-Nathan-M-1569887713226699'}>Nathan Mcnee</a>:</b> for
                    creating the <a href={'https://www.deviantart.com/natesevenseven'}>amazing artwork</a> for the
                    challenge coin
                </li>
                <li className={'Pages-List'}>
                    <b><a href={'https://www.upwork.com/freelancers/~01a11345cf0c09bbaa'}>Lekhraj S.</a>:</b> for
                    creating the beautiful rolling dice GIFs
                </li> 
                <li className={'Pages-List'}>
                    <b><a href={'https://www.upwork.com/freelancers/~01fcaf434b2773be97'}>Kevin MacMaster</a>:</b> for
                    pixel pushing and colors (and other cleanup)
                </li>            </div>
        </>
    );
}