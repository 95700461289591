import React from 'react';
import { Route, Switch, HashRouter} from 'react-router-dom'
import AboutPage from "./About";
import Why from "./Why";
import How from './How'
import CreatePassphrase from "./Passphrase";
import Header from "./Header";
import Coin from "./Coin";


export default function App() {
    return (
        <div className='App'>
            <HashRouter>
                <Header />
                <Switch>
                    {/* This pulls the folder ./Passphrase, which then grabs the index.js file 
                        (which is the parent files that references the rest in that folder*/}
                    <Route path="/" exact component={CreatePassphrase} /> 
                    <Route path="/why" component={Why} />
                    <Route path="/how" component={How} />
                    <Route path="/coin" component={Coin} />
                    <Route path="/about" component={AboutPage} />
                </Switch>
            </HashRouter>
        </div>
    )
}