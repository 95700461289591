import React from "react";
import { Link } from 'react-router-dom'

export default function How() {
    return (
        <>
            <div className={'Pages-Page'}>
                <h1 className={'Pages-Header'}>Roll Dice Virtually</h1>
                <p className={'Pages-Body'}>
                    It's easy to generate a strong passphrase using this site! You can use the virtual
                    twenty-sided dice on the
                    main <Link to={'/'}>Generate page</Link> to "roll" for random words! (See the
                    next section for rolling your own dice.)
                </p>
                <p className={'Pages-Body'}>
                    <b>STEP 1:</b> First, select your dice style: metal, blue, red or green. (See below
                    for "Manual".)
                </p>
                <p className={'Pages-Body'}>
                    <b>STEP 2:</b> Next, choose how many words you want to have in your passphrase. The more words,
                    the stronger it will be. For less important things, you can get away with just 3 or 4 words.
                    For something important like a password vault, I would use at least 6 words.
                </p>
                <p className={'Pages-Body'}>
                    <b>STEP 3:</b> Finally, click each row to "roll" the dice and select a word. Each time you click,
                    the dice will re-roll and choose a new word. (See 'A Few Important Notes' below.)
                </p>
                <p className={'Pages-Body'}>
                    <b>NOTE! :</b> there's some sort of caching behavior that causes <i>all dice</i> of a given
                    number to spin again when any one die of that number spins. That is, if you spin a "16" and
                    there's another "16" already shown on the page, it will re-spin, too. It looks really weird,
                    but it doesn't change the value of the die. I'm working on a fix for this odd behavior. But
                    the key point is that it won't change any rolls (and therefore won't change any of the selected
                    words for those rolls).
                </p>

                <h1 className={'Pages-Header'}>Roll Dice Manually</h1>
                <p className={'Pages-Body'}>
                    If you happen to have some twenty-sided dice ("d20's") lying around
                    (or my spiffy <Link to={'/coin'}>d20 challenge coin</Link>), you can use the
                    "manual" setting and enter your dice roll results by hand. While I tried to use a strong
                    random number generator, there's no beating real dice rolls for true entropy!
                </p>

                <h1 className={'Pages-Header'}>A Few Important Notes</h1>
                <p className={'Pages-Body'}>
                    You can make your passphrase easier to remember by coming up with some sort of personal
                    mnemonic device. I’ll refer you to the
                    obligatory <a href={'https://xkcd.com/936/'}>XKCD comic</a> on the matter.
                </p>
                <p className={'Pages-Body'}>
                    But sometimes the random words are just hard to remember. Maybe you don’t know what they
                    mean. Or maybe you often get them confused with other words with similar spellings.
                    So, if you’re randomly generating your numbers, it’s okay (in my book) to re-roll
                    a <i>few times</i> in order to get words you can more easily remember. You can also re-roll to
                    try to get some shorter words to save on typing. Just don’t do this a lot or it will
                    defeat the purpose (that is, they won’t be very random).
                </p>
                <p className={'Pages-Body'}>
                    Refer to
                    my <a href={'https://firewallsdontstopdragons.com/how-when-to-use-a-passphrase/'}>blog article</a> for
                    more info on this, including the math behind the security of passwords and passphrases.
                </p>
            </div>
        </>
    );
}