import React, { useState } from 'react'

import CopperCoinBack from '../assets/img/coin/copper-back.png'
import CopperCoinFront from '../assets/img/coin/copper-front.png'

import SilveCoinBack from '../assets/img/coin/silver-back.png'
import SilveCoinFront from '../assets/img/coin/silver-front.png'

import GoldCoinBack from '../assets/img/coin/gold-back.png'
import GoldCoinFront from '../assets/img/coin/gold-front.png'
import {useMediaQuery} from 'react-responsive'


const imageMap = {
    'Gold': {
        'front': GoldCoinFront,
        'back': GoldCoinBack
    },
    'Silver': {
        'front': SilveCoinFront,
        'back': SilveCoinBack
    },
    'Copper': {
        'front': CopperCoinFront,
        'back': CopperCoinBack
    }
}

const defaultSize = 300;

const CoinPiece = ({type}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [isFront, setIsFront] = useState(true)

    const src = imageMap[type][isFront ? 'front' : 'back']

    return (
        <div align={'center'} onClick={() => setIsFront(!isFront)}>
            {/* When viewing on mobile, a single image is used here with the src changing based on the user click 
                if not mobile, two images are displayed side by side
            */}
            {isMobile ? 
            <img alt={type} height={defaultSize} src={src}/>
            : <>
                <img alt={type} height={defaultSize} src={imageMap[type]['front']}/>
                <img alt={type} height={defaultSize} src={imageMap[type]['back']}/>
            </>
            }
        </div>
    )
}

export default CoinPiece