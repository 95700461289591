export const DieStyle = {
    Metal: 'metal',
    Red: 'red',
    Blue: 'blue',
    Green: 'green',
    Manual: 'manual'
};

export const ImageSize = 140;

export const DieState = {
    Unrolled: 'unrolled',
    Rolling: 'rolling',
    Rolled: 'rolled'
}

