import ToggleButton from '@material-ui/lab/ToggleButton';
import styled from 'styled-components'
import { DieStyle } from '../assets/types'


/* These changes will affect both of the options rows (die style and number) */
export const StyledToggleButton = styled(ToggleButton)`
    &&&{
        font-weight: bold;
        border-color: #919497;
        padding: 10px 20px;

        /* The hover on an option will be based on the die style selected for the numbers, but the top row actually has its own color */
        &:hover {
            ${props => props.diestyle === DieStyle.Metal ? `background-color: #919497; color: white;` : ''}
            ${props => props.diestyle === DieStyle.Red ? `background-color: #F13320; color: white;` : ''}
            ${props => props.diestyle === DieStyle.Blue ? `background-color: #22C1F3; color: #555;` : ''}
            ${props => props.diestyle === DieStyle.Green ? `background-color: #17F521; color: #555;` : ''}
            box-shadow: inset 0 0 10px #888;
        }
        /* The selected option will have the color based on the die style */
        &&.Mui-selected {
            box-shadow: inset 0 0 10px #555;
            ${props => props.diestyle === DieStyle.Metal ? `background-color: #919497; color: white;` : ''}
            ${props => props.diestyle === DieStyle.Red ? `background-color: #F13320; color: white;` : ''}
            ${props => props.diestyle === DieStyle.Blue ? `background-color: #22C1F3; color: #555;` : ''}
            ${props => props.diestyle === DieStyle.Green ? `background-color: #17F521; color: #555;` : ''}
            z-index: 1 !important;
        }

        @media (max-width: 1224px) {
            width: 18vw;
        }

        @media (min-width: 1224px) {
            width: 200px;
        }
    }
`