import React from 'react';
import words from '../assets/json/word_list.json';
import FormDieInput from './ForDieInput'
import genKey from '../assets/genKey'

class FormRow extends React.Component {
    constructor(props) {
        super(props);
        const map = new Map([
            ['1', ''],
            ['2', ''],
            ['3', '']
        ]);
        this.state = {
            row: this.props.row,
            die_values: map,
            word: '(enter values)'
        }
    }

    handleChange = (die, value) => {
        console.log('FormRow.handleChange(): row=' + this.state.row
            + ', die=' + die
            + ', value' + value
        )
        const newMap = new Map(this.state.die_values)
        newMap.set(die, value)
        this.setState({die_values: newMap})

        const roll1 = newMap.get('1')
        const roll2 = newMap.get('2')
        const roll3 = newMap.get('3')
        console.log('FormRow.handleChange(): roll1=' + roll1
            + ', roll2=' + roll2
            + ', roll3=' + roll3
        )

        if (roll1 && roll2 && roll3) {
            const key = genKey(roll1, roll2, roll3);
            console.log('FormRow.handleChange(): row=' + this.state.row + ", word=" + words[key]);
            this.setState( { word: words[key]})
        }
    }

    render() {
        console.log('FormRow.render(): row=' + this.state.row
            + ', die1=' + this.state.die_values.get('1')
            + ', die2=' + this.state.die_values.get('2')
            + ', die3=' + this.state.die_values.get('3')
        )

        return (
            <div className={'Form-Row'} onChange={this.handleFormFilled}>
                <FormDieInput row={this.state.row} die={'1'} value={this.state.die_values.get('1')} handler={this.handleChange} />
                <FormDieInput row={this.state.row} die={'2'} value={this.state.die_values.get('2')} handler={this.handleChange} />
                <FormDieInput row={this.state.row} die={'3'} value={this.state.die_values.get('3')} handler={this.handleChange} />
                <div className={'Word'}>{this.state.word}</div>
            </div>
        )
    }
}

export default FormRow