export function getRandomInt(min, max) {
    // Lifted from: https://stackoverflow.com/a/18230432
    // Also used by EFF's diceware:
    // https://github.com/EFForg/OpenWireless/blob/master/app/js/diceware.js

    let byteArray = new Uint8Array(1);
    window.crypto.getRandomValues(byteArray);

    let range = max - min + 1;
    let max_range = 256;
    if (byteArray[0] >= Math.floor(max_range / range) * range)
        return getRandomInt(min, max);
    return min + (byteArray[0] % range);
}

