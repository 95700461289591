import React from 'react';

const preventKeys = [173, 69, 190, 189, 187, 110, 109, 107]

class FormDieInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '0',
            row: this.props.row,
            die: this.props.die
        }
    }

    validate = (e) => {
        console.log('FormDieInput.validate(): row=' + this.state.row
            + ', die=' + this.state.die
            + ', value=' + e.target.value)

        let val = e.target.value.replace(/[^0-9]+/g, "")
        console.log(val)
        if ( ! val ) {
            console.log('FormDieInput.validate(): EMPTY')
            return
        } else if ( val < 1 ) {
            val= 1
        } else if ( val > 20 ) {
            val = 20
        }

        e.target.value = val;

        this.props.handler(this.state.die, e.target.value)
    }

    
    removeNonNumbers = (evt) => {
        var key = (evt.charCode) ? evt.charCode : ((evt.which) ? evt.which : evt.keyCode)
        //eslint-disable-next-line
        if (preventKeys.some(p => key == p))
            evt.preventDefault();
    }

    render() {
        return (
            <input className='manual-form-input' type={'number'} min={'1'} max={'20'} onChange={this.validate} onKeyDown={this.removeNonNumbers}/>
        )
    }
}

export default FormDieInput