import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import "./assets/css/Header.css";
import Menu from './assets/img/menu.png';
import Logo from './assets/img/logo-dark.png';
import { CSSTransition } from "react-transition-group";

export default function Header() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 1224px)");
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    const close = () =>{
        setNavVisibility(false)
    }

    return (
        <header className="Header">
            <img src={Logo} className="Logo" alt="logo" />
            <CSSTransition
                in={!isSmallScreen || isNavVisible}
                timeout={350}
                classNames="NavAnimation"
                unmountOnExit
            >
                <nav className="Nav">
                    <Link to="/" onClick={close}>Generate</Link>
                    <Link to="/how" onClick={close}>How?</Link>
                    <Link to="/why" onClick={close}> Why?</Link>
                    <Link to="/coin" onClick={close}>Coin!</Link>
                    <Link to="/about" onClick={close}>About</Link>
                </nav>
            </CSSTransition>
            <button onClick={toggleNav} className="Burger">
                <img height={'30'} alt={'Menu'} src={Menu} />
            </button>
        </header>
    );
}
