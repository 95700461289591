import React from 'react';
import { DieStyle } from '../assets/types'
import DieRow from './DieRow'
import FormRow from './FormRow'

class Passphrase extends React.Component {
    render() {
        console.log('Passphrase.render(): style=' + this.props.style + ', count=' + this.props.count)

        const rows = [];
        if ( this.props.style === DieStyle.Manual ) {
            for (let i=0 ; i < this.props.count ; i++) {
                rows.push(<FormRow key={i} row={i+1}/>)
            }
        } else {
            for (let i=0 ; i < this.props.count ; i++) {
                rows.push(<DieRow key={i} style={this.props.style} row={i+1}/>)
            }
        }

        return (
            <div className={'Die-Grid'}>
                {rows}
            </div>
        );
    }
}

export default Passphrase