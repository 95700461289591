import d20_gif_metal_1 from './assets/img/d20-metal-1.gif';
import d20_gif_metal_2 from './assets/img/d20-metal-2.gif';
import d20_gif_metal_3 from './assets/img/d20-metal-3.gif';
import d20_gif_metal_4 from './assets/img/d20-metal-4.gif';
import d20_gif_metal_5 from './assets/img/d20-metal-5.gif';
import d20_gif_metal_6 from './assets/img/d20-metal-6.gif';
import d20_gif_metal_7 from './assets/img/d20-metal-7.gif';
import d20_gif_metal_8 from './assets/img/d20-metal-8.gif';
import d20_gif_metal_9 from './assets/img/d20-metal-9.gif';
import d20_gif_metal_10 from './assets/img/d20-metal-10.gif';
import d20_gif_metal_11 from './assets/img/d20-metal-11.gif';
import d20_gif_metal_12 from './assets/img/d20-metal-12.gif';
import d20_gif_metal_13 from './assets/img/d20-metal-13.gif';
import d20_gif_metal_14 from './assets/img/d20-metal-14.gif';
import d20_gif_metal_15 from './assets/img/d20-metal-15.gif';
import d20_gif_metal_16 from './assets/img/d20-metal-16.gif';
import d20_gif_metal_17 from './assets/img/d20-metal-17.gif';
import d20_gif_metal_18 from './assets/img/d20-metal-18.gif';
import d20_gif_metal_19 from './assets/img/d20-metal-19.gif';
import d20_gif_metal_20 from './assets/img/d20-metal-20.gif';

import d20_gif_red_1 from './assets/img/d20-red-1.gif';
import d20_gif_red_2 from './assets/img/d20-red-2.gif';
import d20_gif_red_3 from './assets/img/d20-red-3.gif';
import d20_gif_red_4 from './assets/img/d20-red-4.gif';
import d20_gif_red_5 from './assets/img/d20-red-5.gif';
import d20_gif_red_6 from './assets/img/d20-red-6.gif';
import d20_gif_red_7 from './assets/img/d20-red-7.gif';
import d20_gif_red_8 from './assets/img/d20-red-8.gif';
import d20_gif_red_9 from './assets/img/d20-red-9.gif';
import d20_gif_red_10 from './assets/img/d20-red-10.gif';
import d20_gif_red_11 from './assets/img/d20-red-11.gif';
import d20_gif_red_12 from './assets/img/d20-red-12.gif';
import d20_gif_red_13 from './assets/img/d20-red-13.gif';
import d20_gif_red_14 from './assets/img/d20-red-14.gif';
import d20_gif_red_15 from './assets/img/d20-red-15.gif';
import d20_gif_red_16 from './assets/img/d20-red-16.gif';
import d20_gif_red_17 from './assets/img/d20-red-17.gif';
import d20_gif_red_18 from './assets/img/d20-red-18.gif';
import d20_gif_red_19 from './assets/img/d20-red-19.gif';
import d20_gif_red_20 from './assets/img/d20-red-20.gif';

import d20_gif_green_1 from './assets/img/d20-green-1.gif';
import d20_gif_green_2 from './assets/img/d20-green-2.gif';
import d20_gif_green_3 from './assets/img/d20-green-3.gif';
import d20_gif_green_4 from './assets/img/d20-green-4.gif';
import d20_gif_green_5 from './assets/img/d20-green-5.gif';
import d20_gif_green_6 from './assets/img/d20-green-6.gif';
import d20_gif_green_7 from './assets/img/d20-green-7.gif';
import d20_gif_green_8 from './assets/img/d20-green-8.gif';
import d20_gif_green_9 from './assets/img/d20-green-9.gif';
import d20_gif_green_10 from './assets/img/d20-green-10.gif';
import d20_gif_green_11 from './assets/img/d20-green-11.gif';
import d20_gif_green_12 from './assets/img/d20-green-12.gif';
import d20_gif_green_13 from './assets/img/d20-green-13.gif';
import d20_gif_green_14 from './assets/img/d20-green-14.gif';
import d20_gif_green_15 from './assets/img/d20-green-15.gif';
import d20_gif_green_16 from './assets/img/d20-green-16.gif';
import d20_gif_green_17 from './assets/img/d20-green-17.gif';
import d20_gif_green_18 from './assets/img/d20-green-18.gif';
import d20_gif_green_19 from './assets/img/d20-green-19.gif';
import d20_gif_green_20 from './assets/img/d20-green-20.gif';

import d20_gif_blue_1 from './assets/img/d20-blue-1.gif';
import d20_gif_blue_2 from './assets/img/d20-blue-2.gif';
import d20_gif_blue_3 from './assets/img/d20-blue-3.gif';
import d20_gif_blue_4 from './assets/img/d20-blue-4.gif';
import d20_gif_blue_5 from './assets/img/d20-blue-5.gif';
import d20_gif_blue_6 from './assets/img/d20-blue-6.gif';
import d20_gif_blue_7 from './assets/img/d20-blue-7.gif';
import d20_gif_blue_8 from './assets/img/d20-blue-8.gif';
import d20_gif_blue_9 from './assets/img/d20-blue-9.gif';
import d20_gif_blue_10 from './assets/img/d20-blue-10.gif';
import d20_gif_blue_11 from './assets/img/d20-blue-11.gif';
import d20_gif_blue_12 from './assets/img/d20-blue-12.gif';
import d20_gif_blue_13 from './assets/img/d20-blue-13.gif';
import d20_gif_blue_14 from './assets/img/d20-blue-14.gif';
import d20_gif_blue_15 from './assets/img/d20-blue-15.gif';
import d20_gif_blue_16 from './assets/img/d20-blue-16.gif';
import d20_gif_blue_17 from './assets/img/d20-blue-17.gif';
import d20_gif_blue_18 from './assets/img/d20-blue-18.gif';
import d20_gif_blue_19 from './assets/img/d20-blue-19.gif';
import d20_gif_blue_20 from './assets/img/d20-blue-20.gif';


import d20_gif_metal_1_last from './assets/img/d20-metal-1-lastframe.gif';
import d20_gif_metal_2_last from './assets/img/d20-metal-2-lastframe.gif';
import d20_gif_metal_3_last from './assets/img/d20-metal-3-lastframe.gif';
import d20_gif_metal_4_last from './assets/img/d20-metal-4-lastframe.gif';
import d20_gif_metal_5_last from './assets/img/d20-metal-5-lastframe.gif';
import d20_gif_metal_6_last from './assets/img/d20-metal-6-lastframe.gif';
import d20_gif_metal_7_last from './assets/img/d20-metal-7-lastframe.gif';
import d20_gif_metal_8_last from './assets/img/d20-metal-8-lastframe.gif';
import d20_gif_metal_9_last from './assets/img/d20-metal-9-lastframe.gif';
import d20_gif_metal_10_last from './assets/img/d20-metal-10-lastframe.gif';
import d20_gif_metal_11_last from './assets/img/d20-metal-11-lastframe.gif';
import d20_gif_metal_12_last from './assets/img/d20-metal-12-lastframe.gif';
import d20_gif_metal_13_last from './assets/img/d20-metal-13-lastframe.gif';
import d20_gif_metal_14_last from './assets/img/d20-metal-14-lastframe.gif';
import d20_gif_metal_15_last from './assets/img/d20-metal-15-lastframe.gif';
import d20_gif_metal_16_last from './assets/img/d20-metal-16-lastframe.gif';
import d20_gif_metal_17_last from './assets/img/d20-metal-17-lastframe.gif';
import d20_gif_metal_18_last from './assets/img/d20-metal-18-lastframe.gif';
import d20_gif_metal_19_last from './assets/img/d20-metal-19-lastframe.gif';
import d20_gif_metal_20_last from './assets/img/d20-metal-20-lastframe.gif';

import d20_gif_red_1_last from './assets/img/d20-red-1-lastframe.gif';
import d20_gif_red_2_last from './assets/img/d20-red-2-lastframe.gif';
import d20_gif_red_3_last from './assets/img/d20-red-3-lastframe.gif';
import d20_gif_red_4_last from './assets/img/d20-red-4-lastframe.gif';
import d20_gif_red_5_last from './assets/img/d20-red-5-lastframe.gif';
import d20_gif_red_6_last from './assets/img/d20-red-6-lastframe.gif';
import d20_gif_red_7_last from './assets/img/d20-red-7-lastframe.gif';
import d20_gif_red_8_last from './assets/img/d20-red-8-lastframe.gif';
import d20_gif_red_9_last from './assets/img/d20-red-9-lastframe.gif';
import d20_gif_red_10_last from './assets/img/d20-red-10-lastframe.gif';
import d20_gif_red_11_last from './assets/img/d20-red-11-lastframe.gif';
import d20_gif_red_12_last from './assets/img/d20-red-12-lastframe.gif';
import d20_gif_red_13_last from './assets/img/d20-red-13-lastframe.gif';
import d20_gif_red_14_last from './assets/img/d20-red-14-lastframe.gif';
import d20_gif_red_15_last from './assets/img/d20-red-15-lastframe.gif';
import d20_gif_red_16_last from './assets/img/d20-red-16-lastframe.gif';
import d20_gif_red_17_last from './assets/img/d20-red-17-lastframe.gif';
import d20_gif_red_18_last from './assets/img/d20-red-18-lastframe.gif';
import d20_gif_red_19_last from './assets/img/d20-red-19-lastframe.gif';
import d20_gif_red_20_last from './assets/img/d20-red-20-lastframe.gif';

import d20_gif_green_1_last from './assets/img/d20-green-1-lastframe.gif';
import d20_gif_green_2_last from './assets/img/d20-green-2-lastframe.gif';
import d20_gif_green_3_last from './assets/img/d20-green-3-lastframe.gif';
import d20_gif_green_4_last from './assets/img/d20-green-4-lastframe.gif';
import d20_gif_green_5_last from './assets/img/d20-green-5-lastframe.gif';
import d20_gif_green_6_last from './assets/img/d20-green-6-lastframe.gif';
import d20_gif_green_7_last from './assets/img/d20-green-7-lastframe.gif';
import d20_gif_green_8_last from './assets/img/d20-green-8-lastframe.gif';
import d20_gif_green_9_last from './assets/img/d20-green-9-lastframe.gif';
import d20_gif_green_10_last from './assets/img/d20-green-10-lastframe.gif';
import d20_gif_green_11_last from './assets/img/d20-green-11-lastframe.gif';
import d20_gif_green_12_last from './assets/img/d20-green-12-lastframe.gif';
import d20_gif_green_13_last from './assets/img/d20-green-13-lastframe.gif';
import d20_gif_green_14_last from './assets/img/d20-green-14-lastframe.gif';
import d20_gif_green_15_last from './assets/img/d20-green-15-lastframe.gif';
import d20_gif_green_16_last from './assets/img/d20-green-16-lastframe.gif';
import d20_gif_green_17_last from './assets/img/d20-green-17-lastframe.gif';
import d20_gif_green_18_last from './assets/img/d20-green-18-lastframe.gif';
import d20_gif_green_19_last from './assets/img/d20-green-19-lastframe.gif';
import d20_gif_green_20_last from './assets/img/d20-green-20-lastframe.gif';

import d20_gif_blue_1_last from './assets/img/d20-blue-1-lastframe.gif';
import d20_gif_blue_2_last from './assets/img/d20-blue-2-lastframe.gif';
import d20_gif_blue_3_last from './assets/img/d20-blue-3-lastframe.gif';
import d20_gif_blue_4_last from './assets/img/d20-blue-4-lastframe.gif';
import d20_gif_blue_5_last from './assets/img/d20-blue-5-lastframe.gif';
import d20_gif_blue_6_last from './assets/img/d20-blue-6-lastframe.gif';
import d20_gif_blue_7_last from './assets/img/d20-blue-7-lastframe.gif';
import d20_gif_blue_8_last from './assets/img/d20-blue-8-lastframe.gif';
import d20_gif_blue_9_last from './assets/img/d20-blue-9-lastframe.gif';
import d20_gif_blue_10_last from './assets/img/d20-blue-10-lastframe.gif';
import d20_gif_blue_11_last from './assets/img/d20-blue-11-lastframe.gif';
import d20_gif_blue_12_last from './assets/img/d20-blue-12-lastframe.gif';
import d20_gif_blue_13_last from './assets/img/d20-blue-13-lastframe.gif';
import d20_gif_blue_14_last from './assets/img/d20-blue-14-lastframe.gif';
import d20_gif_blue_15_last from './assets/img/d20-blue-15-lastframe.gif';
import d20_gif_blue_16_last from './assets/img/d20-blue-16-lastframe.gif';
import d20_gif_blue_17_last from './assets/img/d20-blue-17-lastframe.gif';
import d20_gif_blue_18_last from './assets/img/d20-blue-18-lastframe.gif';
import d20_gif_blue_19_last from './assets/img/d20-blue-19-lastframe.gif';
import d20_gif_blue_20_last from './assets/img/d20-blue-20-lastframe.gif';

const metal_dice = {
    '1': d20_gif_metal_1,
    '2': d20_gif_metal_2,
    '3': d20_gif_metal_3,
    '4': d20_gif_metal_4,
    '5': d20_gif_metal_5,
    '6': d20_gif_metal_6,
    '7': d20_gif_metal_7,
    '8': d20_gif_metal_8,
    '9': d20_gif_metal_9,
    '10': d20_gif_metal_10,
    '11': d20_gif_metal_11,
    '12': d20_gif_metal_12,
    '13': d20_gif_metal_13,
    '14': d20_gif_metal_14,
    '15': d20_gif_metal_15,
    '16': d20_gif_metal_16,
    '17': d20_gif_metal_17,
    '18': d20_gif_metal_18,
    '19': d20_gif_metal_19,
    '20': d20_gif_metal_20,
}

const green_dice = {
    '1': d20_gif_green_1,
    '2': d20_gif_green_2,
    '3': d20_gif_green_3,
    '4': d20_gif_green_4,
    '5': d20_gif_green_5,
    '6': d20_gif_green_6,
    '7': d20_gif_green_7,
    '8': d20_gif_green_8,
    '9': d20_gif_green_9,
    '10': d20_gif_green_10,
    '11': d20_gif_green_11,
    '12': d20_gif_green_12,
    '13': d20_gif_green_13,
    '14': d20_gif_green_14,
    '15': d20_gif_green_15,
    '16': d20_gif_green_16,
    '17': d20_gif_green_17,
    '18': d20_gif_green_18,
    '19': d20_gif_green_19,
    '20': d20_gif_green_20,
}

const red_dice = {
    '1': d20_gif_red_1,
    '2': d20_gif_red_2,
    '3': d20_gif_red_3,
    '4': d20_gif_red_4,
    '5': d20_gif_red_5,
    '6': d20_gif_red_6,
    '7': d20_gif_red_7,
    '8': d20_gif_red_8,
    '9': d20_gif_red_9,
    '10': d20_gif_red_10,
    '11': d20_gif_red_11,
    '12': d20_gif_red_12,
    '13': d20_gif_red_13,
    '14': d20_gif_red_14,
    '15': d20_gif_red_15,
    '16': d20_gif_red_16,
    '17': d20_gif_red_17,
    '18': d20_gif_red_18,
    '19': d20_gif_red_19,
    '20': d20_gif_red_20,
}

const blue_dice = {
    '1': d20_gif_blue_1,
    '2': d20_gif_blue_2,
    '3': d20_gif_blue_3,
    '4': d20_gif_blue_4,
    '5': d20_gif_blue_5,
    '6': d20_gif_blue_6,
    '7': d20_gif_blue_7,
    '8': d20_gif_blue_8,
    '9': d20_gif_blue_9,
    '10': d20_gif_blue_10,
    '11': d20_gif_blue_11,
    '12': d20_gif_blue_12,
    '13': d20_gif_blue_13,
    '14': d20_gif_blue_14,
    '15': d20_gif_blue_15,
    '16': d20_gif_blue_16,
    '17': d20_gif_blue_17,
    '18': d20_gif_blue_18,
    '19': d20_gif_blue_19,
    '20': d20_gif_blue_20,
}

export const dieGIFs = new Map();
dieGIFs['metal'] = metal_dice;
dieGIFs['red'] = red_dice;
dieGIFs['blue'] = blue_dice;
dieGIFs['green'] = green_dice;


const metal_dice_last = {
    '1': d20_gif_metal_1_last,
    '2': d20_gif_metal_2_last,
    '3': d20_gif_metal_3_last,
    '4': d20_gif_metal_4_last,
    '5': d20_gif_metal_5_last,
    '6': d20_gif_metal_6_last,
    '7': d20_gif_metal_7_last,
    '8': d20_gif_metal_8_last,
    '9': d20_gif_metal_9_last,
    '10': d20_gif_metal_10_last,
    '11': d20_gif_metal_11_last,
    '12': d20_gif_metal_12_last,
    '13': d20_gif_metal_13_last,
    '14': d20_gif_metal_14_last,
    '15': d20_gif_metal_15_last,
    '16': d20_gif_metal_16_last,
    '17': d20_gif_metal_17_last,
    '18': d20_gif_metal_18_last,
    '19': d20_gif_metal_19_last,
    '20': d20_gif_metal_20_last,
}

const green_dice_last = {
    '1': d20_gif_green_1_last,
    '2': d20_gif_green_2_last,
    '3': d20_gif_green_3_last,
    '4': d20_gif_green_4_last,
    '5': d20_gif_green_5_last,
    '6': d20_gif_green_6_last,
    '7': d20_gif_green_7_last,
    '8': d20_gif_green_8_last,
    '9': d20_gif_green_9_last,
    '10': d20_gif_green_10_last,
    '11': d20_gif_green_11_last,
    '12': d20_gif_green_12_last,
    '13': d20_gif_green_13_last,
    '14': d20_gif_green_14_last,
    '15': d20_gif_green_15_last,
    '16': d20_gif_green_16_last,
    '17': d20_gif_green_17_last,
    '18': d20_gif_green_18_last,
    '19': d20_gif_green_19_last,
    '20': d20_gif_green_20_last,
}

const red_dice_last = {
    '1': d20_gif_red_1_last,
    '2': d20_gif_red_2_last,
    '3': d20_gif_red_3_last,
    '4': d20_gif_red_4_last,
    '5': d20_gif_red_5_last,
    '6': d20_gif_red_6_last,
    '7': d20_gif_red_7_last,
    '8': d20_gif_red_8_last,
    '9': d20_gif_red_9_last,
    '10': d20_gif_red_10_last,
    '11': d20_gif_red_11_last,
    '12': d20_gif_red_12_last,
    '13': d20_gif_red_13_last,
    '14': d20_gif_red_14_last,
    '15': d20_gif_red_15_last,
    '16': d20_gif_red_16_last,
    '17': d20_gif_red_17_last,
    '18': d20_gif_red_18_last,
    '19': d20_gif_red_19_last,
    '20': d20_gif_red_20_last,
}

const blue_dice_last = {
    '1': d20_gif_blue_1_last,
    '2': d20_gif_blue_2_last,
    '3': d20_gif_blue_3_last,
    '4': d20_gif_blue_4_last,
    '5': d20_gif_blue_5_last,
    '6': d20_gif_blue_6_last,
    '7': d20_gif_blue_7_last,
    '8': d20_gif_blue_8_last,
    '9': d20_gif_blue_9_last,
    '10': d20_gif_blue_10_last,
    '11': d20_gif_blue_11_last,
    '12': d20_gif_blue_12_last,
    '13': d20_gif_blue_13_last,
    '14': d20_gif_blue_14_last,
    '15': d20_gif_blue_15_last,
    '16': d20_gif_blue_16_last,
    '17': d20_gif_blue_17_last,
    '18': d20_gif_blue_18_last,
    '19': d20_gif_blue_19_last,
    '20': d20_gif_blue_20_last,
}

export const dieGIFs_last = new Map();
dieGIFs_last['metal'] = metal_dice_last;
dieGIFs_last['red'] = red_dice_last;
dieGIFs_last['blue'] = blue_dice_last;
dieGIFs_last['green'] = green_dice_last;

