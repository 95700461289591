import React from 'react';
import '../assets/css/CreatePassphrase.css';
import DieStyleOption from './DieStyleOption';
import WordCountOption from './WordCountOption';
import Passphrase from './Passphrase'
import { DieStyle } from '../assets/types'

class CreatePassphrase extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            die_style: DieStyle.Metal,
            word_count: '3',
        }
    }

    handleChooseDieStyle = (e, value) => {
        console.log('App.handleChooseDieStyle(), die_style=' + value)
        if ( ! value ) {
            return
        }
        this.setState({
            die_style: value
        })
    }

    handleChooseWordCount = (e, value) => {
        console.log('App.handleChooseWordCount(), word_count=' + value)
        if ( ! value ) {
            return
        }
        this.setState({
            word_count: value
        })
    }

    render() {
        return (
            <div className="CreatePassphrase">
                <h2>Generate a Passphrase</h2>
                <DieStyleOption
                    diestyle={this.state.die_style}
                    handler={this.handleChooseDieStyle}
                />
                <WordCountOption
                    count={this.state.word_count}
                    handler={this.handleChooseWordCount}
                    diestyle={this.state.die_style}
                />
                <Passphrase
                    count={this.state.word_count}
                    style={this.state.die_style}/>
            </div>
        )
    }
}

export default CreatePassphrase;
