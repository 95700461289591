import React, { useEffect, useState } from 'react';

import {dieGIFs, dieGIFs_last} from "../images";

import words from '../assets/json/word_list.json';
import { getRandomInt } from '../randomInteger.js'
import genKey from '../assets/genKey'
import {DieState} from "../assets/types";

const DieRow = ({row, style}) => {
    const [dieState, setDieState] = useState(DieState.Unrolled)
    const [die1, setDie1] = useState(20)
    const [die2, setDie2] = useState(20)
    const [die3, setDie3] = useState(20)
    const [word, setWord] = useState('(click)')
    const [die1Src, setDie1Src] = useState(dieGIFs[style][die1])
    const [die2Src, setDie2Src] = useState(dieGIFs[style][die2])
    const [die3Src, setDie3Src] = useState(dieGIFs[style][die3])
    console.log('[row ' + row + '] dieState = ' + dieState)

    const rollD20 = () => {
        return getRandomInt(1,20)
    }

    const handleClick = () => {
        const roll1 = rollD20()
        const roll2 = rollD20()
        const roll3 = rollD20()
        const key = genKey(roll1, roll2, roll3);

        setDieState(DieState.Rolling)
        setDie1(roll1)
        setDie2(roll2)
        setDie3(roll3)
        setWord('(rolling...)')

        // Wait for the dice to stop rolling before showing the word
        setTimeout(() => {
            console.log('[row ' + row + '] timer pop!! word=' + words[key]);
            setDieState(DieState.Rolled)
            setWord(words[key])
            setDie1Src(dieGIFs_last[style][roll1])
            setDie2Src(dieGIFs_last[style][roll2])
            setDie3Src(dieGIFs_last[style][roll3])
        }, 2500);
    }

    useEffect(() => {
        if (die1 != null) {
            if (dieState === DieState.Rolled) {
                setDie1Src(dieGIFs_last[style][die1])
                setDie2Src(dieGIFs_last[style][die2])
                setDie3Src(dieGIFs_last[style][die3])
            } else {
                setDie1Src(dieGIFs[style][die1])
                setDie2Src(dieGIFs[style][die2])
                setDie3Src(dieGIFs[style][die3])
            }

        }
        // eslint-disable-next-line
    }, [die1, style])

    return (
        <div className={'Die-Row'} onClick={handleClick}>
            <img alt={die1} className="die" src={die1Src}/>
            <img alt={die2} className="die" src={die2Src}/>
            <img alt={die3} className="die" src={die3Src}/>
            <div className={'Word'}>{word}</div>
        </div>
    )

}


export default DieRow;