import React from 'react';

import {useMediaQuery} from 'react-responsive'
import { DieStyle } from '../assets/types'
import { StyledToggleButton } from './StyledToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const DieStyleOption = (props) => {
    console.log('ChooseDieStyle: style=' + props.style)
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
            <ToggleButtonGroup
                value={props.diestyle}
                exclusive
                onChange={props.handler}
                size={isMobile ? 'small': 'large'}
            >
                {/* The button's own die style is passed in order to make the hover state it's own color */}
                <StyledToggleButton value={DieStyle.Metal} diestyle={DieStyle.Metal}>Metal</StyledToggleButton>
                <StyledToggleButton value={DieStyle.Red} diestyle={DieStyle.Red}>Red</StyledToggleButton>
                <StyledToggleButton value={DieStyle.Blue} diestyle={DieStyle.Blue}>Blue</StyledToggleButton>
                <StyledToggleButton value={DieStyle.Green} diestyle={DieStyle.Green}>Green</StyledToggleButton>
                <StyledToggleButton value={DieStyle.Manual}>MANUAL</StyledToggleButton>
            </ToggleButtonGroup>
    );
}

export default DieStyleOption