import React from 'react';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {useMediaQuery} from 'react-responsive'
import {StyledToggleButton} from './StyledToggleButton'

const WordCountOption = (props) => {
    console.log('ChooseWordCount: count=' + props.count)
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <ToggleButtonGroup
            value={props.count}
            exclusive
            onChange={props.handler}
            size={isMobile ? 'medium' : 'large'}
        >
            <StyledToggleButton value={'3'} diestyle={props.diestyle}>3</StyledToggleButton>
            <StyledToggleButton value={'4'} diestyle={props.diestyle}>4</StyledToggleButton>
            <StyledToggleButton value={'5'} diestyle={props.diestyle}>5</StyledToggleButton>
            <StyledToggleButton value={'6'} diestyle={props.diestyle}>6</StyledToggleButton>
            <StyledToggleButton value={'7'} diestyle={props.diestyle}>7</StyledToggleButton>
        </ToggleButtonGroup>
    );
}

export default WordCountOption

